import { Descriptions, Spin, Tag, Typography, Table } from "antd";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

import Page from "components/Page";
import { Portal } from "components/Portal";
import ThreatByDate from "components/report/TheatByDate";
import DnsLog from "components/table/DnsLog";
import TarpitLog from "components/table/TarpitLog";
import TopCountries from "components/report/TopCountries";
import TopVictims from "components/report/TopVictims";

import supabase from "lib/supabase/main";
import useQuery from "hooks/useQuery";
import ThreatName from "components/ThreatName";


async function loadMalwareInfo({ malwareId }) {
    return await supabase.from("threat")
        .select("*")
        .eq("id", malwareId);
}

function MalwarePage() {

    const { pathname } = useLocation();
    const malwareId = pathname.split("/").pop();

    const { data: malware, isLoading } = useQuery(
        loadMalwareInfo,
        [malwareId],
        {
            params: {
                malwareId
            },
            prepare: (data) => data[0],
        }
    );

    const tags = useMemo(
        () => {
            if (!malware) {
                return [];
            }
            const tags = [
                {
                    key: "type",
                    label: "Type",
                    children: [
                        <Tag key="type">{malware.type}</Tag>
                    ]
                }
            ]

            // if (malware.alias) {
            //     tags.push({
            //         key: "alias",
            //         label: "Alias",
            //         children: [
            //             <Tag key="alias">{malware.alias}</Tag>
            //         ]
            //     });
            // }

            // if (malware.actor) {
            //     tags.push({
            //         key: "actor",
            //         label: "Actor",
            //         children: [
            //             <Tag key="actor">{malware.actor}</Tag>
            //         ]
            //     });
            // }

            if (malware.malpedia_data?.alt_names &&
                malware.malpedia_data.alt_names.length > 0) {
                tags.push({
                    key: "alt_names",
                    label: "Alt Names",
                    children: malware.malpedia_data.alt_names.map(name => (
                        <Tag key={name}>{name}</Tag>
                    ))
                });
            }

            if (malware.malpedia_data?.attribution &&
                malware.malpedia_data.attribution.length > 0) {
                tags.push({
                    key: "attribution",
                    label: "Attribution",
                    children: malware.malpedia_data.attribution.map(attribution => (
                        <Tag key={attribution}>{attribution}</Tag>
                    ))
                });
            }

            if (malware.malpedia_data?.urls &&
                malware.malpedia_data.urls.length > 0) {
                const rows = malware.malpedia_data.urls.map(url => ({ url }));
                const columns = [
                    {
                        title: "URL",
                        dataIndex: "url",
                        key: "url",
                        render: (url) => (
                            <a key={url} href={url} target="_blank" rel="noreferrer">{url}</a>
                        )
                    }
                ]
                tags.push({
                    key: "urls",
                    label: "URLs",
                    children: (
                        <Table
                            style={{ width: "100%" }}
                            bordered={false}
                            size="small"
                            dataSource={rows}
                            columns={columns} />
                    )
                });
            }

            if (malware.yara_available) {
                const url = `https://malpedia.caad.fkie.fraunhofer.de/yara/${malware.id}`;
                tags.push({
                    key: "yara",
                    label: "YARA rules",
                    children: [
                        <a href={url} target="_blank" rel="noreferrer">Download YARA rules</a>
                    ]
                });
            }

            return tags;
        },
        [malware]
    );

    const threatByDateParams = useMemo(
        () => ({
            threatId: malwareId
        }),
        [malwareId]
    );

    const dnsLogParams = useMemo(
        () => ({
            threatId: malwareId,
            // withThreat: true
        }),
        [malwareId]
    );

    const tarpitLogParams = useMemo(
        () => ({
            threatId: malwareId,
            // withThreat: true
        }),
        [malwareId]
    );

    const topCountriesParams = useMemo(
        () => ({
            threatId: malwareId,
            period: "last7"
        }),
        [malwareId]
    );

    const topVictimsParams = useMemo(
        () => ({
            threatId: malwareId,
        }),
        [malwareId]
    );

    return (
        <Page className="page-malware">
            <Portal host="header">
                <Spin spinning={isLoading}>
                    <Typography.Title level={1}>
                        <ThreatName
                            withLink={false}
                            id={malwareId}
                            name={malware?.name} />
                    </Typography.Title>
                    <Typography.Text>{malware?.description}</Typography.Text>
                    <ThreatByDate
                        params={threatByDateParams}
                        showToolbar={false}
                        chartType="bar" />
                </Spin>
            </Portal>

            {tags.length > 0 &&
                <section>
                    <Descriptions
                        bordered
                        className="page-malware-tags"
                        column={1}
                        items={tags} />
                </section>}

            <section>
                <TopCountries
                    title="Top countries"
                    bordered
                    params={topCountriesParams} />
            </section>

            <section>
                <TopVictims
                    title="Top victims"
                    bordered
                    params={topVictimsParams} />
            </section>

            <section>
                <Typography.Title level={4}>Dns log</Typography.Title>
                <DnsLog
                    initialParams={dnsLogParams} />
            </section>
            <section>
                <Typography.Title level={4}>Tarpit log</Typography.Title>
                <TarpitLog
                    initialParams={tarpitLogParams} />
            </section>

        </Page>
    )
}

export default MalwarePage;