import { useMemo } from "react"
import { Typography, Spin, Table } from "antd"
import { Link } from "react-router-dom"

import useQuery from "hooks/useQuery"

import supabase from "lib/supabase/main"

import countries from "dict/countries.json"
import formatNumber from "lib/format/number"
import CountryName from "components/CountryName"

const { Title } = Typography;


const columns = [
    {
        dataIndex: "country",
        key: "country",
        title: "Country",
        render: country => {
            //const name = countries.find(c => c.code === country)?.name || "Unknown";
            const c = countries.find(c => c.code === country);
            return (
                <Link to={`/reports/country/${country}`} children={
                    <CountryName country={c} />
                } />
            )
        }
    },
    {
        dataIndex: "ip_cnt",
        key: "ip_cnt",
        title: "Ip count",
        render: formatNumber
    },
    {
        dataIndex: "dns_cnt",
        key: "dns_cnt",
        title: "Dns log entries",
        render: formatNumber
    },
    {
        dataIndex: "tarpit_cnt",
        key: "tarpit_cnt",
        title: "Tarpit log entries",
        render: formatNumber
    },
]

async function loadTopCountries(options = {}) {

    const { data: { rows, columns }, error } = await supabase.functions.invoke("query", {
        body: {
            report: "top_threat_domain_countries",
            options,
            //replica: true
        },
        method: "POST"
    });

    return { data: rows, columns, error }
}

function TopThreatDomainCountries({
    title = "Top countries",
    params = {},
    bordered = false,
    countryLink = true,
    onDataLoad = null }) {

    const { data, isLoading } = useQuery(
        loadTopCountries,
        Object.values(params),
        {
            onLoad: onDataLoad,
            params: {
                limit: 10,
                ...params
            }
        },
        Object.values(params)
    );

    const tableColumns = useMemo(
        () => {
            let tableColumns = [...columns];
            if (countryLink === false) {
                tableColumns[0].render = country => (
                    <CountryName country={countries.find(c => c.code === country)} />
                )
                // return countries.find(c => c.code === country)?.name || "Unknown";
            }

            return tableColumns;
        },
        [countryLink]
    )

    return (
        <div className="small-report report-top-threat-domains">
            <div className="toolbar">
                {title && <Title level={4}>{title}</Title>}
            </div>
            <Spin spinning={isLoading}>
                <Table
                    sticky
                    size="small"
                    bordered={bordered}
                    loading={isLoading}
                    dataSource={data}
                    columns={tableColumns}
                    rowKey="country"
                    pagination={false} />
            </Spin>
        </div>
    )

}

export default TopThreatDomainCountries