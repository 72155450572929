import { useCallback, useMemo } from "react"
import { Typography, Descriptions, Spin } from "antd"
import { useLocation, useNavigate } from "react-router-dom"

import Page from "components/Page"
import DnsLogTable from "components/table/DnsLog"
import ColumnList from "components/ColumnList"
import HuntReportLogTable from "components/table/HuntReportLog"
import TopVictims from "components/report/TopVictims"
import DomainByDate from "components/report/DomainByDate"
import RegistrarLogTable from "components/table/RegistrarLog"
import ThreatFoxIocTable from "components/table/ThreatFoxIoc"
import TopThreatDomainCountries from "components/report/TopThreatDomainCountries"

import useQuery from "hooks/useQuery"
import supabase from "lib/supabase/main"
import TarpitLog from "components/table/TarpitLog"
import { Portal } from "components/Portal"

const { Title } = Typography;

async function loadDomainVariants({ domain }) {

    const { data, error } = await supabase.from("threat_domain_variant")
        .select("variant")
        .eq("domain", domain);
    return { data, error }
}

function Domain() {

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const domain = pathname.split("/").pop();

    const renderSubdomain = useCallback(
        (row, inx) => (<p key={inx}>{row.variant}</p>),
        []
    );

    const { data: subdomains, isLoading: isSubdomainsLoading } = useQuery(
        loadDomainVariants,
        [domain],
        {
            params: {
                domain
            }
        }
    );

    const topVictimsParams = useMemo(
        () => ({ domainId: domain, limit: 10, pageSize: 10, withCompany: true }),
        [domain]
    );

    const registrarParams = useMemo(
        () => ({ domain }),
        [domain]
    )

    const iocParams = useMemo(
        () => ({ domain }),
        [domain]
    );

    const dnsLogParams = useMemo(
        () => ({
            domainId: domain,
            pageSize: 10
        }),
        [domain]
    );

    const tarpitLogParams = useMemo(
        () => ({
            domainId: domain,
            pageSize: 10
        }),
        [domain]
    );

    const topThreatDomainCountriesParams = useMemo(
        () => ({ domain }),
        [domain]
    );

    const onLoadMoreDns = useCallback(
        () => {
            navigate(`/logs/dns?domainId=${domain}`)
        },
        [domain, navigate]
    );

    const onLoadMoreTarpit = useCallback(
        () => {
            navigate(`/logs/tarpit?domainId=${domain}`)
        },
        [domain, navigate]
    );

    return (
        <Page className="page-domain">
            <Portal host="header">
                <div className="header-single-row">
                    <Title level={1}>{domain}</Title>
                    <DomainByDate
                        showToolbar={false}
                        title={null}
                        params={{ domainId: domain }}
                        chartType="bar" />
                </div>
            </Portal>
            <Spin spinning={isSubdomainsLoading}>
                <Descriptions bordered className="bg-page">
                    <Descriptions.Item label="Domain variants">
                        <ColumnList data={subdomains} renderItem={renderSubdomain} columns={8} />
                    </Descriptions.Item>
                </Descriptions>
            </Spin>

            <section>
                <Title level={4}>Registrar info</Title>
                <RegistrarLogTable showToolbar={false} initialParams={registrarParams} />
            </section>

            <section className="gradient-section">
                <Title level={4}>IOC</Title>
                <ThreatFoxIocTable
                    initialParams={iocParams}
                    showToolbar={false}
                    allowMore={false} />
                <br />
                <Title level={4}>Threats</Title>
                <HuntReportLogTable
                    showToolbar={false}
                    showPagination={false}
                    initialParams={{ domain, withCount: false }} />
            </section>


            <TopVictims params={topVictimsParams} bordered />
            <TopThreatDomainCountries params={topThreatDomainCountriesParams} bordered />

            <section>
                <Title level={4}>Dns log</Title>
                <DnsLogTable
                    ipLink
                    excludeQueryOptions={["questionDomain"]}
                    initialParams={dnsLogParams}
                    onLoadMore={onLoadMoreDns} />
            </section>

            <section>
                <Title level={4}>Tarpit log</Title>
                <TarpitLog
                    ipLink
                    initialParams={tarpitLogParams}
                    onLoadMore={onLoadMoreTarpit} />
            </section>
        </Page>
    )
}

export default Domain